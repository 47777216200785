<template>
  <div>
    <video id="video" width="444" controls playinline autobuffer autoplay muted @click="onVideoClick"></video>
    <br>
    <v-btn color="primary" @click="muteStream">mute</v-btn>
    <v-btn color="primary" @click="toggleVideoStream">video off/on</v-btn>
    <v-btn color="error">leave meeting</v-btn>
    <v-btn color="error" @click="endMeeting">end meeting</v-btn>
  </div>
</template>

<script>
export default {
  data () {
    return {
      iceServers: [{
        urls: [ 'stun:bn-turn1.xirsys.com' ]
      }, {
        username: 'OJb2KPABGPhvq7tKKC0eTRSHpGZ0YbFDF64QGOYZwXN79aWsHF7Kps3CXPqm_ixNAAAAAGDIcYxyaXlhenU=',
        credential: '556e794c-cdbb-11eb-b1c6-0242ac140004',
        urls: [
          'turn:bn-turn1.xirsys.com:80?transport=udp',
          'turn:bn-turn1.xirsys.com:3478?transport=udp',
          'turn:bn-turn1.xirsys.com:80?transport=tcp',
          'turn:bn-turn1.xirsys.com:3478?transport=tcp',
          'turns:bn-turn1.xirsys.com:443?transport=tcp',
          'turns:bn-turn1.xirsys.com:5349?transport=tcp'
        ]
      }],
      localStream: null,
      remoteStream: null,
      turnReady: null,
      isChannelReady: false,
      isInitiator: false,
      isStarted: false
    }
  },
  computed: {
    pc () {
      return new RTCPeerConnection(this.iceServers)
    }
  },
  mounted () {
    // const video = document.querySelector('#video')
    // this.getUserFullMedia()
    //   .then(stream => {
    //     video.srcObject = stream
    //   })
    // console.log(window.RTCPeerConnection, window.RTCSessionDescription)
    // var myPeer = new Peer()
    // console.log(myPeer)
  },
  methods: {
    getUserFullMedia () { // returns the vieeo & audio stream
      return window.navigator.mediaDevices.getUserMedia({
        video: true,
        audio: {
          echoCancellation: true,
          noiseSuppression: true
        }
      })
    },
    onVideoClick () {
      if (!document.pictureInPictureElement) {
        document
          .querySelector('#video')
          .requestPictureInPicture()
          .catch((err) => {
            // Video failed to enter Picture-in-Picture mode.
            console.error(err)
          })
      } else {
        document.exitPictureInPicture().catch((error) => {
          // Video failed to leave Picture-in-Picture mode.
          console.error(error)
        })
      }
    },
    muteStream () {
      const video = document.querySelector('video')
      let stream = video.srcObject
      stream.getAudioTracks()[0].enabled = !stream.getAudioTracks()[0].enabled
    },
    toggleVideoStream () {
      const video = document.querySelector('video')
      let stream = video.srcObject
      stream.getVideoTracks()[0].enabled = !stream.getVideoTracks()[0].enabled
    },
    createPeerConnection () {
      try {
        this.pc.onicecandidate = this.handleIceCandidate()
        this.pc.onaddstream = this.handleRemoteStreamAdded()
        this.pc.onremovestream = this.handleRemoteStreamRemoved()
      } catch (err) {
        console.log('Failed to create a peer connection, exception: ' + err.message)
      }
    },
    handleIceCandidate (event) {
      if (event.candidate) {
        this.sendMessage({
          type: 'candidate',
          label: event.candidate.sdpMLineIndex,
          id: event.candidate.sdpMid,
          candidate: event.candidate.candidate
        }, this.$route.params.id)
      }
      else console.log('end of candidates.')
    },
    handleRemoteStreamAdded (event) {
      this.remoteStream = event.stream
      // let remoteVideo = {}
      // remoteVideo.srcObject = this.remoteStream
    },
    handleRemoteStreamRemoved (event) {
      console.log('Remote stream removed of ' + event)
    },
    // sendMessage (msg, room) {
    //   if (msg === 'got user media') {}
    // },
    maybeStart () {},
    endMeeting () {
      let payload = { tenant: this.tenant, groupId: '60c1d0acc93405308c336171' }
      const video = document.querySelector('video')
      if (video && video.srcObject) {
        let stream = video.srcObject
        stream.getTracks().forEach((track) => {
          track.stop()
        })
      }
      this.$socket.send('endStream', payload)
      this.$router.push(`/${ this.tenant }/vc`)
    }
  },
  beforeDestroy () {
    const video = document.querySelector('video')
    if (video && video.srcObject) {
      let stream = video.srcObject
      stream.getTracks().forEach((track) => {
        track.stop()
      })
    }
  }
}
</script>

<style scoped></style>